import { type DefaultProps, Text, UnstyledButton, createStyles } from '@mantine/core'
import Link from 'next/link'
import type { MenuLink } from '../types/Menu.type'

const useStyles = createStyles(theme => ({
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: theme.radius.md,
    height: 120,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    transition: 'box-shadow 150ms ease, transform 100ms ease',

    '&:hover': {
      boxShadow: `${theme.shadows.md} !important`,
      transform: 'scale(1.05)',
    },
  },
}))

export type TSAQuickAccessButtonProps = DefaultProps &
  MenuLink & {
    color?: string
    hasPermission?: boolean
  }

export const TSAQuickAccessButton = ({
  IconElement,
  color,
  label,
  link,
  hasPermission,
  divider,
  ...props
}: TSAQuickAccessButtonProps) => {
  const { classes } = useStyles()

  return hasPermission ? (
    <UnstyledButton className={classes.item} component={Link} href={link ?? ''} {...props}>
      {IconElement && <IconElement color={color} size={44} />}
      <Text size="xl" mt={7} weight="bold" color="gray.8">
        {label}
      </Text>
    </UnstyledButton>
  ) : (
    <></>
  )
}
